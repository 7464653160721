import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Seo from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  return (
    <>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className={`blog-post `}>
        {/* <div className={`banner`}></div> */}
        <div className={`page-heading`}>
          <div className={`overlay`}></div>
        </div>
        <div className={`page-content `}>
          <div className={`container `}>
            <div className={`row border border-warning`}>
              <div className={`col-lg-10 mx-auto  `}>
                <article>
                  <header>
                    <h1
                      style={{
                        marginTop: rhythm(1),
                        lineHeight: `1.5em`,
                        marginBottom: 0,
                      }}
                    >
                      {post.frontmatter.title}
                    </h1>
                    <p
                      style={{
                        ...scale(-1 / 5),
                        display: `block`,
                        marginBottom: rhythm(1),
                      }}
                    >
                      {post.frontmatter.date}
                    </p>
                  </header>
                  <section dangerouslySetInnerHTML={{ __html: post.html }} />
                  <hr
                    style={{
                      marginBottom: rhythm(1),
                    }}
                  />
                  <footer>
                    <Bio />
                  </footer>
                </article>
                <nav>
                  <ul
                    style={{
                      display: `flex`,
                      flexWrap: `wrap`,
                      justifyContent: `space-between`,
                      listStyle: `none`,
                      padding: 0,
                    }}
                  >
                    <li>
                      {previous && (
                        <Link to={previous.fields.slug} rel="prev">
                          ← {previous.frontmatter.title}
                        </Link>
                      )}
                    </li>
                    <li>
                      {next && (
                        <Link to={next.fields.slug} rel="next">
                          {next.frontmatter.title} →
                        </Link>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
